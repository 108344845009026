const maxHeightClasses: Record<number, string> = {
  6: 'max-h-[6rem]',
  19: 'max-h-[19rem]',
}

const DEFAULT_MAX_HEIGHT_CLASS = maxHeightClasses[6]
function getMaxHeightClass(value: number, defaultClass = DEFAULT_MAX_HEIGHT_CLASS) {
  return maxHeightClasses[value] ?? defaultClass
}

export { getMaxHeightClass }
