import {
  GUNSLINGER_JOB_STATUSES,
  GunslingerJobStatus,
  IterationComputedStatus,
  IterationStatus,
} from '@/const/const.ts'

const VM_OVERRIDE_STATUSES = [
  GUNSLINGER_JOB_STATUSES.STOPPED,
  GUNSLINGER_JOB_STATUSES.STOPPING,
  GUNSLINGER_JOB_STATUSES.ERROR,
] as const

export function getIterationComputedStatus(
  iterationStatus: IterationStatus | null,
  vmStatus: GunslingerJobStatus | null | undefined
): IterationComputedStatus | null {
  if (vmStatus && VM_OVERRIDE_STATUSES.includes(vmStatus)) {
    return vmStatus
  }

  return iterationStatus
}

export function formatIterationStatus(status: IterationComputedStatus): string {
  if (!status) {
    return ''
  }
  return status.replace(/_/g, ' ')
}

export function generateGCPLoggingLink(filters) {
  // We do that so the project can easily change from the envs
  const gcpProjectId = import.meta.env.VITE_GCP_LOGS_PROJECT_ID
  const baseUrl = 'https://console.cloud.google.com/logs/query'
  const queryParts = Object.entries(filters).map(([key, value]) => `${key}="${value}"`)
  const query = queryParts.join(' AND ')
  const encodedQuery = encodeURIComponent(query)
  const link = `${baseUrl};query=${encodedQuery}?project=${gcpProjectId}`
  return link
}
